import { IRecentColleagueInfo } from "@src/app/store/recentColleagues/model";
import { IWorkerFriend, IWorkerFriendRequest } from "@src/app/store/workerFriends/model";
import { getScoreType } from "@src/appV2/AttendanceScore/utils";
import { Shift } from "@src/lib/interface/src";

import { fetchShiftSlots } from "./api";
import { IBookedBy, IShiftSlot } from "./interface";
import { IColleagueInfo } from "./workWithFriends.types";

export const fetchShiftSlotsFromOpenShifts = async (
  fetchOpenShiftsResult: Shift[]
): Promise<IShiftSlot[]> => {
  const shiftSlotMap = new Set();
  const shiftSlotsRanges = [] as Array<{
    workplaceUserId: string;
    range: {
      start: string;
      end: string;
    };
  }>;
  fetchOpenShiftsResult.forEach(({ facilityId, start, end }) => {
    if (facilityId && start && end) {
      const mapKey = `${facilityId},${start},${end}`;
      if (!shiftSlotMap.has(mapKey)) {
        shiftSlotMap.add(mapKey);
        shiftSlotsRanges.push({
          workplaceUserId: facilityId.toString(),
          range: {
            start,
            end,
          },
        });
      }
    }
  });
  shiftSlotMap.clear();
  const shiftSlotResponse = await fetchShiftSlots({
    workplaceSlots: shiftSlotsRanges,
  });

  return shiftSlotResponse;
};

export const fetchNumFamiliarColleaguesAndFriendsShown = (
  recentColleagues: IRecentColleagueInfo[] | null,
  friends: IWorkerFriend[] | null,
  bookedBy: IShiftSlot["bookedBy"]
): { numFamiliarColleaguesShown: number; numFriendsShown: number } => {
  let numFriendsShown = 0;
  let numFamiliarColleaguesShown = 0;
  if (!bookedBy.length) {
    return { numFamiliarColleaguesShown, numFriendsShown };
  }

  bookedBy.forEach((shiftColleague) => {
    const isFriend = friends?.some((friend) => shiftColleague.userId === friend.workerId);
    if (isFriend) {
      numFriendsShown++;
    } else {
      const isRecentColleague = recentColleagues?.some(
        (recentColleague) => shiftColleague.userId === recentColleague.colleagueUserId
      );
      if (isRecentColleague) {
        numFamiliarColleaguesShown++;
      }
    }
  });
  return { numFamiliarColleaguesShown, numFriendsShown };
};

export function getNumberOfOpenShiftsInSlot(stats: IShiftSlot["stats"]) {
  return stats
    ? Object.values(stats)?.reduce(
        (accumulator, stat) => accumulator + (stat?.totalShifts - stat?.assignedShifts),
        0
      )
    : 0;
}

export const getGroupAttendanceBonusSummary = (
  addFriendBonus: number | undefined,
  groupAttendanceBonus: number | undefined,
  isClipboardScoreEnabled: boolean,
  isReliabilityRankingExperimentEnabled: boolean
): string | null => {
  if (!addFriendBonus && !groupAttendanceBonus) {
    return null;
  }
  let groupAttendanceBonusSummary = "You'll also earn ";
  if (addFriendBonus) {
    const scoreType = getScoreType({
      isReliabilityRankingExperimentEnabled,
      isClipboardScoreEnabled,
    });
    groupAttendanceBonusSummary += `${addFriendBonus} points to your ${scoreType} for adding a friend`;
  }
  if (addFriendBonus && groupAttendanceBonus) {
    groupAttendanceBonusSummary += " and ";
  }
  if (groupAttendanceBonus) {
    groupAttendanceBonusSummary += `${groupAttendanceBonus} points per friend when working a shift together and nobody cancels`;
  }
  groupAttendanceBonusSummary += ".";
  return groupAttendanceBonusSummary;
};

export const getColleagueList = ({
  hideAsColleagueOnShifts,
  workerFriends,
  recentColleagues,
  workerFriendRequests,
  shiftSlotBookedByList,
  isShiftCompleted,
  userId,
}: {
  hideAsColleagueOnShifts: boolean | undefined;
  workerFriends: IWorkerFriend[];
  recentColleagues?: IRecentColleagueInfo[];
  workerFriendRequests: IWorkerFriendRequest[] | null;
  shiftSlotBookedByList: IBookedBy[];
  isShiftCompleted: boolean;
  userId?: string;
}): IColleagueInfo[] => {
  if (isShiftCompleted) {
    if (!shiftSlotBookedByList || hideAsColleagueOnShifts) {
      return [];
    }
  } else if (hideAsColleagueOnShifts && !workerFriends?.length) {
    return [];
  }

  let computedColleagueList = shiftSlotBookedByList?.map((shiftColleague) => {
    let recentlyWorkedColleague: IRecentColleagueInfo | null | undefined = null;
    if (!isShiftCompleted && recentColleagues) {
      recentlyWorkedColleague = hideAsColleagueOnShifts
        ? null
        : recentColleagues.find((worker) => shiftColleague.userId === worker.colleagueUserId);
    }
    const isFriend = workerFriends.some((friend) => friend.workerId === shiftColleague.userId);
    const isFriendRequestSent = workerFriendRequests?.some(
      (friendRequest) => friendRequest.workerId === shiftColleague.userId
    );
    return {
      colleagueUserId: shiftColleague.userId,
      colleagueName: shiftColleague.name,
      qualification: shiftColleague.qualification,
      hasProfilePic: shiftColleague.hasProfilePic,
      ...(!isShiftCompleted && recentlyWorkedColleague
        ? {
            shiftStart: recentlyWorkedColleague.shiftStart,
            facilityName: recentlyWorkedColleague.facilityName,
            shiftName: recentlyWorkedColleague.shiftName,
          }
        : {}),
      ...(isFriend ? { isFriend: true } : {}),
      isFriendRequestSent,
    };
  });

  computedColleagueList = computedColleagueList.filter(
    (colleague) => colleague.colleagueUserId !== userId
  );

  if (!isShiftCompleted && hideAsColleagueOnShifts) {
    computedColleagueList = computedColleagueList.filter((colleague) => colleague.isFriend);
  }

  return computedColleagueList.sort((colleague1, colleague2) => {
    if (colleague1.isFriend && !colleague2.isFriend) {
      return -1;
    }
    if (!colleague1.isFriend && colleague2.isFriend) {
      return 1;
    }
    if (!colleague1.isFriendRequestSent && colleague2.isFriendRequestSent) {
      return -1;
    }
    if (colleague1.isFriendRequestSent && !colleague2.isFriendRequestSent) {
      return 1;
    }
    if (!isShiftCompleted && recentColleagues) {
      if (colleague1.shiftStart && !colleague2.shiftStart) {
        return -1;
      }
      if (!colleague1.shiftStart && colleague2.shiftStart) {
        return 1;
      }
    }
    if (colleague1.hasProfilePic && !colleague2.hasProfilePic) {
      return -1;
    }
    if (!colleague1.hasProfilePic && colleague2.hasProfilePic) {
      return 1;
    }
    return 0;
  });
};
